const $ = require('jquery');
require('bootstrap');
require('hammerjs');

window.spin = function () {
  $('.spinner').removeClass('disabled');
}

window.stopSpinning = function () {
  $('.spinner').addClass('disabled');
}

$(document).ready(function () {
  $('.mdropdown-toggle').dropdown();
  $('.carousel').carousel();
  $('.carousel').each(function () {
    var $carousel = $(this);
    var hammertime = new Hammer(this, {
      recognizers: [
        [Hammer.Swipe, { direction: Hammer.DIRECTION_HORIZONTAL }]
      ]
    });

    hammertime.on('swipeleft', function () {
      $carousel.carousel('next');
    });

    hammertime.on('swiperight', function () {
      $carousel.carousel('prev');
    });
  });

  $('.quantity-select').on('change', function () {
    $(this).closest('form').submit();
  });

  $('body').on('mouseenter mouseleave','.dropdown',function(e){
    var dropdown = $(e.target).closest('.dropdown');
    dropdown.addClass('show');

    setTimeout(function () {
      dropdown[dropdown.is(':hover') ? 'addClass' : 'removeClass']('show');
      $('[data-toggle="dropdown"]', dropdown).attr('aria-expanded', dropdown.is(':hover'));
    }, 200);
  });
});
